@import "../../../assets/redesignedCss/main";

.loginToolbar {
  padding: 10px 15px 0 15px;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;

  .logoContainer {
    margin-top: 8px;
    width: 153px;
    height: 64px;

    button {
      width: 100%;
      height: 64px;
      border: 0 none;
      display: block;
      background-color: transparent;
      cursor: pointer;
      background-image: url("../../../assets/images/logo/wv_logo_white.svg");
      background-repeat: no-repeat;
      background-size: contain;
    }

    &.colorLogo {
      button {
        background-image: url("../../../assets/images/logo/wv_logo_black.svg");
      }
    }
  }
  a.loginButton {
    float: right;
    color: $color-real-white;
    border: 1px solid $color-real-white;
    border-radius: 4px;
    padding: 7px 15px;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
  }
}

@media (max-width: $breakpoint-phone) {
  .loginToolbar {
    padding-bottom: 0;

    .logoContainer {
      text-align: center;
    }
  }
}