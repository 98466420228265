@use "sass:map";
@use "sass:math";

@import "./colors";
@import "./variables";
///BASE

html {
  background-color: #FFF;
  font-size: $font-size;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

body {
  color: $plain-text;
  font-family: $font-family !important;
  line-height: $line-height;
  font-weight: $weight-regular;
  font-size: 1rem;
  overflow-x: hidden;
  padding-right: 0 !important;
  min-height: 100%;
  margin: 0;

  -webkit-font-smoothing: subpixel-antialiased;
  *, *:before, *:after {
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

body.-lock {
  overflow: hidden;
}

img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;

  &[data-sizes="auto"] {
    display: block;
    width: 100%;
  }
}

ul[class] {
  list-style-type: none;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  cursor: pointer;
}

::selection {
  background: $border;
}

:focus-visible {
  outline: 3px dashed $color-sky !important;
}

.skip-link {
  position: fixed;
  top: 0;
  left: 0;
  padding: .5rem 1rem;
  transform: translateY(-100%);
  display: block;
  transition: transform $moderate $entrance-easing, box-shadow $moderate $entrance-easing;
  box-shadow: 0 6px 80px rgba($color-gray, 0);
  z-index: 915;
  background-color: #fff;
  color: $new-brand-link-color;
}

.skip-link:focus {
  transform: translateY(0) !important;
  box-shadow: 0 6px 80px rgba($color-gray, .25);
}

.container {
  max-width: 1262px;
  margin: 0 auto;
  padding: 0 1.777775rem;
}

header #showWhenLoggedIn {
  display: none;
}

header #showWhenLoggedOut {
  display: none;
}

.mainBodyWrapper:focus-visible {
  outline: 3px dashed $color-sky !important;
  outline-offset: -3px;
}
///BASE

///HEADINGS
@mixin heading-one {
  font-size: 2.222222rem;
  font-weight: $weight-demibold;
  color: $color-navy;
  line-height: 1.2;

  @media (min-width: $minMdl) {
    font-size: 3rem;
    line-height: 1.075;
  }
}

@mixin heading-two {
  font-size: 2.222222rem;
  font-weight: $weight-demibold;
  color: $color-navy;
  line-height: 1.2;
}

@mixin heading-three {
  font-size: 1.777777rem;
  line-height: 1.1875;
  font-weight: $weight-demibold;
  color: $color-navy;
}

@mixin heading-four {
  font-size: 1.33333rem;
  line-height: 1.208;
  font-weight: $weight-demibold;
  color: $color-navy;
}

@mixin at($bp, $rule: min-width) {
  @if map.has-key($breakpoints, $bp, width) {
      $bp: map.get($breakpoints, $bp, width);
  }
  @media screen and (#{$rule}: $bp) {
      @content;
  }
}

@mixin atMax($bp, $rule: max-width) {
  @if map.has-key($breakpoints, $bp, width) {
      $bp: map.get($breakpoints, $bp, width);
  }
  @media screen and (#{$rule}: $bp) {
      @content;
  }
}

h1,
.h1 {
  margin-top: 0;
  @include heading-one;
}

h2,
.h2 {
  margin-top: 0;
  @include heading-two;
}

h3,
.h3 {
  margin-top: 0;
  @include heading-three;
}

h4,
.h4 {
  margin-top: 0;
  @include heading-four;
}
///HEADINGS

///ALERT
.alert {
  padding: 0.9rem;
  text-align: center;
  background-color: #c3e8ec;
  border-radius: 15px;
}
///ALERT

/// OLD GLOBAL
.materialIcon {
  font-family: 'material', serif;
  font-feature-settings: 'liga';
  text-rendering: optimizeLegibility;
}

button {
  &.textButton {
    padding: 10px 30px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    color: $color-real-white;
    border-radius: 3px;
    cursor: pointer;
    min-width: 100px;
    font-size: 13px;

    &:disabled {
      background-color: $color-neutral-new20;
      color: $color-neutral-new21;
      cursor: not-allowed;
    }

    &.action {
      background-color: $color-brand-medium-dark;
      color: $color-real-white;
      border: 1px solid $color-brand-medium-dark;
      text-transform: capitalize;
      font-size: 18px;
      font-weight: 400;

      &:hover {
        background-color: $color-brand-hover;
        border: 1px solid $color-brand-hover;
      }

      &:disabled {
        background-color: $color-brand-dark;
        border-color: $color-brand-dark;
        color: $color-neutral-new21;

        &:hover {
          background-color: $color-brand-dark;
          border-color: $color-brand-dark;
        }
      }

      &.smallFont {
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    &.cta {
      border: 1px solid $color-cta;
      background-color: $color-cta;

      &:hover {
        border: 1px solid $color-cta-light;
        background-color: $color-cta-light;
      }

      &:disabled {
        background-color: $color-cta-dark;
        border: 1px solid $color-cta-dark;
        color: $color-neutral4;
      }
    }

    &.leftMargin {
      margin-left: 10px;
    }

    &.hollow {
      color: $color-neutral3;
      border: 1px solid $color-neutral3;
      background: 0 none;

      &:hover {
        color: $color-black;
        background-color: $color-neutral4;
      }

      &.smallFont {
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    &.blueButton {
      background-color: $color-brand-medium-dark;
      color: $color-real-white;
      border: 1px solid $color-brand-medium-dark;

      &:hover {
        background-color: $color-brand-light;
        border: 1px solid $color-brand-light;
      }
    }

    &.hollowBrand {
      background: 0 none;
      color: $color-brand-medium-dark;
      border: 1px solid $color-brand-medium-dark;
      font-size: 12px;
      font-weight: 700;

      &:hover {
        background-color: $color-brand-hover;
        border: 1px solid $color-brand-hover;
        color: $color-real-white;
      }
    }

    &:active, &:focus {
      outline: none;
    }
  }
}

.payNowWrapper {
  background-color: $color-neutral-new4;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  padding: 32px 0;

  .textButton {
    &.checkout {
      height: 44px;
      width: 228px;
      text-transform: capitalize;
      font-size: 18px;
      font-weight: 400;
      border-radius: 4px;
      margin-top: 15px;
    }
  }
}

.checkoutUpgradeWrapper {
  .textButton {
    &.checkout {
      font-size: 13px;
      font-weight: 700;
      border: 1px solid $color-cta;
      background-color: $color-cta;
      padding: 8px 16px;

      &:hover {
        border: 1px solid $color-cta-light;
        background-color: $color-cta-light;
      }

      &:disabled {
        background-color: $color-cta-dark;
        border: 1px solid $color-cta-dark;
        color: $color-neutral4;
      }

      &:disabled {
        &:hover {
          background-color: $color-cta-dark;
          border: 1px solid $color-cta-dark;
          color: $color-neutral4;
        }
      }
    }
  }
}

.singleExportViewWrapper {
  .textButton {
    &.checkout {
      font-size: 13px;
      font-weight: 700;
      border: 1px solid $color-cta;
      background-color: $color-cta;
      padding: 8px 16px;
      text-transform: uppercase;

      &:hover {
        border: 1px solid $color-cta-light;
        background-color: $color-cta-light;
      }

      &:disabled {
        background-color: $color-cta-dark;
        border: 1px solid $color-cta-dark;
        color: $color-neutral4;
      }

      &:disabled {
        &:hover {
          background-color: $color-cta-dark;
          border: 1px solid $color-cta-dark;
          color: $color-neutral4;
        }
      }
    }
  }
}

.paypalButtonV2 {
  width: 100%;
  height: 32px;
  background-image: url("../images/paypal-logo.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-color: $color-orange-new2;
  border: 0;
  border-radius: 4px;
  cursor: pointer;

  &.checkoutDisabled {
    opacity: .5;
    background-color: $color-neutral-new9;
    cursor: not-allowed;
  }
  &:active, &:focus {
    outline: none;
  }
}

.stripeFormWrapper, .stripeCardFormWrapper {
  .payment-errors {
    font-size: 12px;
    color: $color-error-form;
    text-align: left;
    font-weight: 400;
  }
}

.textAlignRight {
  text-align: right;
}

.textAlignCenter {
  text-align: center;
}

.hidden {
  display: none;
}

a.primary {
  text-decoration: none;
  color: $color-brand-medium-dark;
  cursor: pointer;

  &:active,
  &:hover,
  &:focus {
    color: $color-brand-hover;
  }
}

a.second-primary,
span.second-primary {
  text-decoration: none;
  color: $color-brand-medium-dark;
  cursor: pointer;

  &:active,
  &:focus {
    color: $color-brand-hover;
  }

  &:hover {
    text-decoration: underline;
    color: $color-brand-hover;
  }

  &.underline {
    text-decoration: underline;
  }
}

input[type="number"],
input[type="password"],
input[type="text"]:not(.infoBox),
textarea {

  font-size: 16px;
  line-height: 20px;
  width: 100%;
  outline: none;
  border-radius: 4px;
  border: 1px solid $color-neutral3;
  padding: 8px;
  background-color: $color-real-white;
}

.__react_component_tooltip {
  border-radius: 3px;
  display: inline-block;
  font-size: 13px;
  left: -999em;
  opacity: 0;
  padding: 8px 21px;
  position: fixed;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
  top: -999em;
  visibility: hidden;
  z-index: 999;
}
.title .__react_component_tooltip {
  &.show {
    margin-top: -20px;
  }
}
.title .__react_component_tooltip.show#publishTime {
  margin-top: 0;
  max-height: 100%;
  padding: 2px 10px;
}

input[type="radio"] {
  opacity: 0;
  position: absolute;
}

input[type="radio"] + label.radio-btn:before {
  content: "";
  background-color: $color-neutral5;
  border: 2px solid $color-neutral3;
  border-radius: 50%;
  cursor: pointer;
  font: inherit;
  height: 14px;
  outline: none;
  width: 14px;
  display: inline-block;
  margin: 0 0.75rem 0 0;
}

input[type="radio"]:checked + label.radio-btn:before {
  background-color: $color-brand;
  -webkit-transition: background .2s;
  transition: background .2s;
}

//-> start default radio btn
input[type="radio"]:checked + label.radio-btn-default,
input[type="radio"]:not(:checked) + label.radio-btn-default {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}

input[type="radio"]:checked + label.radio-btn-default:before,
input[type="radio"]:not(:checked) + label.radio-btn-default:before {
  content: '';
  position: absolute;
  top: 4px;
  left: 0;
  width: 12px;
  height: 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  background: $color-real-white;
}

input[type="radio"]:checked + label.radio-btn-default:before {
  background: $color-brand;
}

input[type="radio"]:checked + label.radio-btn-default:after,
input[type="radio"]:not(:checked) + label.radio-btn-default:after {
  content: '';
  width: 4px;
  height: 4px;
  background: $color-real-white;
  position: absolute;
  top: 9px;
  left: 5px;
  border-radius: 50%;
}

table {
  font-size: 16px;
}
.getChromeBtn {
  text-align: center;

  .getChromeLink {
    color: $color-brand-medium-dark;
    cursor: pointer;
    font-size: 13px;
    text-transform: uppercase;
    padding: 2px;
    font-weight: 700;
    text-decoration: underline;

    &:hover {
      color: $color-brand-hover;
    }
  }
}

.loadedInModalHeader {
  position: fixed;
  width: 100%;
  text-align: right;
  right: 5px;
  top: 4px;

  span.closeLoadedInModal {
    color: $color-black;
    font-size: 24px;
    width: 20px;
    height: 20px;
    display: inline-block;
    cursor: pointer;
    padding: 4px;

    &.plansPageCloseIcon {
      color: $color-neutral1;
    }
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

#optanon, #optanon *, #optanon div, #optanon span, #optanon ul, #optanon li, #optanon a, #optanon p, .optanon-alert-box-wrapper * {
  font-family: "Montreux Grotesk Soft", sans-serif !important;
}
body .optanon-alert-box-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}
.optanon-alert-box-wrapper .optanon-alert-box-bg {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.optanon-alert-box-wrapper .optanon-alert-box-bg .optanon-alert-box-body {
  padding: 0;
  margin: auto;
  position: relative;
  display: block;
}
body .optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle {
  padding: 0 0 0 4px;
}
.optanon-alert-box-wrapper .optanon-alert-box-bg .optanon-alert-box-body p {
  font-size: 12px;
  text-align: left;
  padding: 0;
  line-height: 1.15;
}
.optanon-alert-box-wrapper .optanon-alert-box-bg .optanon-alert-box-logo {
  display: none;
}
.optanon-alert-box-wrapper .optanon-alert-box-bg .optanon-clearfix {
  display: none;
}
.optanon-alert-box-wrapper .optanon-alert-box-bg .optanon-alert-box-button-container {
  position: relative;
  display: inline-block;
  margin: 0;
  top: auto;
  right: auto;
}
.optanon-alert-box-wrapper .optanon-alert-box-bg .optanon-alert-box-button-container .optanon-alert-box-button {
  float: none;
  margin: 0;
}
.optanon-alert-box-wrapper .optanon-alert-box-bg .optanon-alert-box-body p a.optanon-toggle-display {
  font-size: 12px;
  line-height: 1.15;
  border-bottom: 0;
  color: #28A2B5!important;
  font-weight: 700;
}
.optanon-alert-box-wrapper .optanon-alert-box-bg .optanon-alert-box-body p a.optanon-toggle-display:hover {
  text-decoration: underline;
}
.optanon-alert-box-wrapper .optanon-alert-box-bg .optanon-alert-box-body p a.optanon-toggle-display:before {
  font-size: 10px;
  top: 0;
  display: none;
}
.optanon-alert-box-wrapper .optanon-alert-box-bottom-top {
  height: auto!important;
  width: auto;
  display: flex;
  align-items: center;
}
.optanon-alert-box-wrapper .optanon-alert-box-bottom-top .optanon-alert-box-corner-close {
  position: relative;
  height: auto;
  margin-top: 0;
  top: auto;
  right: auto;
  font-size: 12px;
  line-height: 1.15;
  display: flex;
  width: 100%;
  min-width: 300px;
  justify-content: space-evenly;
}
.optanon-alert-box-wrapper .optanon-alert-box-corner-close a.accept-all-cookies {
  border: 2px solid #28A2B5;
  background: #28A2B5 none;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  float: none;
  right: auto;
  top: auto;
  width: auto;
  height: auto;
  border-radius: 25px;
  padding: 8px 30px;
}
.optanon-alert-box-wrapper .optanon-alert-box-corner-close a.accept-all-cookies:hover {
  text-decoration: underline;
}
.optanon-alert-box-wrapper .optanon-alert-box-corner-close a.reject-all-cookies {
  border: 2px solid #28A2B5;
  background: transparent;
  color: #003A5D;
  font-size: 12px;
  font-weight: 600;
  float: none;
  right: auto;
  top: auto;
  width: auto;
  height: auto;
  border-radius: 25px;
  padding: 8px 30px;
}
.optanon-alert-box-wrapper .optanon-alert-box-corner-close a.reject-all-cookies:hover {
  background: #28A2B5 none;
  color: #ffffff;
}
@media (max-width: 1200px) {
  .optanon-alert-box-wrapper .optanon-alert-box-bottom-top {
    min-width: 350px;
  }
  body .optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle {
    text-align: center;
  }
  .optanon-alert-box-wrapper .optanon-alert-box-bg .optanon-alert-box-body {
    padding: 5px 20px;
  }

  .optanon-alert-box-wrapper .optanon-alert-box-bg .optanon-alert-box-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  body .optanon-alert-box-wrapper {
    flex-direction: column-reverse;
  }
  .optanon-alert-box-wrapper .optanon-alert-box-bg {
    flex-direction: column;
  }
  .optanon-alert-box-wrapper .optanon-alert-box-bg .optanon-alert-box-body {
    padding-top: 15px;
  }
  .optanon-alert-box-wrapper .optanon-alert-box-bg .optanon-alert-box-body p {
    text-align: center;
  }
  .optanon-alert-box-wrapper .optanon-alert-box-corner-close {
    padding-top: 0;
    padding-bottom: 20px;
    bottom: auto!important;
  }
  .optanon-alert-box-wrapper .optanon-alert-box-bg .optanon-alert-box-button-container {
    padding-top: 15px;
    padding-right: 0;
  }
}

#hs-banner-parent {
  display: none!important;
  opacity: 0!important;
  visibility: hidden!important;
}
.FormElementsCheckBox {
  label {
    cursor: pointer;
  }
}
/// OLD GLOBAL