@import "../../../../../assets/redesignedCss/main";

.stripeCardFormWrapper {
  position: relative;

  .payment-errors {
    position: relative;
    padding-left: 8px;
    font-size: 11px;
    margin-top: -15px;
    margin-bottom: 15px;
  }

  #payment-card-form .__react_component_tooltip {
    opacity: .96;
    background-color: $color-neutral1;
    min-width: 335px;
  }

  .form-row {
    position: relative;

    .disable-card-fields {
      border: 1px solid #a7a7a7 !important;
      background-color: $color-neutral-new23 !important;
    }
  }

  .StripeElement.card {
    border: 1px solid $color-neutral3;
    border-radius: 4px;

    &.StripeElement--focus {
      border-color: $color-brand;
    }
  }

  span.cardType {
    background-repeat: no-repeat;
    background-size: 32px 20px;
    position: absolute;
    top: 33px;
    right: 7px;
    display: block;
    width: 32px;
    height: 20px;
    z-index: 20;
    pointer-events: none;

    &.visa {
      background-image: url("../../../../../assets/images/cc-icons/visa_color.svg");
    }

    &.amex {
      background-image: url("../../../../../assets/images/cc-icons/amex_color.svg");
    }

    &.mastercard {
      background-image: url("../../../../../assets/images/cc-icons/mastercard_color.svg");
    }

    &.discover {
      background-image: url("../../../../../assets/images/cc-icons/discover_color.svg");
    }
  }
  .padding8 {
    padding: 8px;
  }
}