@import "../../../../../assets/redesignedCss/main";

.stripeFormWrapper {

  #payment-form .__react_component_tooltip {
    opacity: .96;
    background-color: $color-neutral1;
    min-width: 335px;
  }

  .form-row {
    position: relative;

    .disable-card-fields {
      border: 1px solid #a7a7a7 !important;
      background-color: $color-neutral-new23 !important;
    }
  }

  span.cardType {
    background-repeat: no-repeat;
    background-size: 32px 20px;
    position: absolute;
    top: 33px;
    right: 7px;
    display: block;
    width: 32px;
    height: 20px;
    z-index: 20;
    pointer-events: none;

    &.visa {
      background-image: url("../../../../../assets/images/cc-icons/visa.png") !important;
    }

    &.amex {
      background-image: url("../../../../../assets/images/cc-icons/amex.png") !important;
    }

    &.mastercard {
      background-image: url("../../../../../assets/images/cc-icons/mastercard.png") !important;
    }

    &.discover {
      background-image: url("../../../../../assets/images/cc-icons/discover.png") !important;
    }
  }

  .cardCVC {

    .materialIcon {
      color: $color-neutral4;
      font-size: 12px;
      vertical-align: middle;
      margin-left: 2px;
    }

    .cvc-tooltip-container {
      height: 128px;
      width: 335px;
      display: flex;
      margin-top: 16px;
      margin-bottom: 16px;

      .cards-container {
        display: flex;
        flex-direction: column;
        margin-right: 26px;
        justify-content: space-between;
        margin-bottom: 21px;

        .card-placeholder {
          height: 36px;
          width: 59px;
          background-size: cover;
          background-repeat: no-repeat;

          &.visa-mc {
            background-image: url("//d3tvj3lw2y3r4c.cloudfront.net/hub/img/payment/visa_mc_cvc.svg");
          }

          &.amex {
            background-image: url("//d3tvj3lw2y3r4c.cloudfront.net/hub/img/payment/amex_cvc.svg");
          }
        }
      }

      .cards-info-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .card-info {
          width: 205px;

          p {
            margin: 0;
          }

          .card-title {
            font-size: 14px;
            color: $color-white-old;
            font-weight: bold;
            line-height: 16px;
          }

          .card-description {
            font-size: 12px;
            color: $color-white-old;
            line-height: 16px;
          }
        }
      }
    }
  }
}