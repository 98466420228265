// Icon Fonts
@font-face {
  font-family: "wevideo";
  src: url("../fonts/wevideo.eot");
  src: url("../fonts/wevideo.eot?#iefix") format("embedded-opentype"),
  url("../fonts/wevideo.woff") format("woff"),
  url("../fonts/wevideo.ttf") format("truetype"),
  url("../fonts/wevideo.svg#wevideo") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "material";
  src: url("../fonts/MaterialIcons-Regular.eot");
  src: local('material'),
  local('Material Icons'),
  local('MaterialIcons-Regular'),
  url("../fonts/MaterialIcons-Regular.woff2") format("woff2"),
  url("../fonts/MaterialIcons-Regular.woff") format("woff"),
  url("../fonts/MaterialIcons-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montreux Grotesk Soft';
  src: url('../fonts/MontreuxGS-Bk.woff2') format('woff2'),
  url('../fonts/MontreuxGS-Bk.woff2') format('woff');
  font-weight: 350;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montreux Grotesk Soft';
  src: url('../fonts/MontreuxGS-DmBd.woff2') format('woff2'),
  url('../fonts/MontreuxGS-DmBd.woff2') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montreux Grotesk Soft';
  src: url('../fonts/MontreuxGS-Bd.woff2') format('woff2'),
  url('../fonts/MontreuxGS-Bd.woff2') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montreux Grotesk Soft';
  src: url('../fonts/MontreuxGS-Rg.woff2') format('woff2'),
  url('../fonts/MontreuxGS-Rg.woff2') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}