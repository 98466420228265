@import "../../../assets/redesignedCss/main";

.acceptedCcIcons {
  padding-left: 8px;

  span.acceptedCardIcon {
    display: inline-block;
    width: 40px;
    height: 25px;
    margin-right: 5px;
    background-size: cover;
    border: 1px solid $color-neutral5;
    border-radius: 2px;

    &.active {
      opacity: 1;
    }

    &.visa {
      background-image: url("../../../assets/images/cc-icons/visa_color.svg");
    }

    &.american.express {
      background-image: url("../../../assets/images/cc-icons/amex_color.svg");
    }

    &.mastercard {
      background-image: url("../../../assets/images/cc-icons/mastercard_color.svg");
    }

    &.discover {
      background-image: url("../../../assets/images/cc-icons/discover_color.svg");
    }
  }

  .stripe-logo {
    display: inline-block;
    margin-left: 10px;

    .poweredByStripe {
      background-image: url("../../../assets/images/cc-icons/power-by-stripe.svg");
      width: 103px;
      height: 17px;
      display: block;
    }
  }
}