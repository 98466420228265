@import "../../../assets/redesignedCss/main";

.applicationLoader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9991;
  background: rgba(51, 51, 51, 0.7) url("../../../assets/images/dots-loader.svg") center center no-repeat;
}