@import "../../assets/redesignedCss/main";

.new-password-page-wrapper {
  box-sizing: border-box;
  font-family: "Montreux Grotesk Soft", sans-serif !important;
  margin-top: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-size: cover;
  background-image: linear-gradient(0deg, #02afc1, #066477);
  position: relative;
  align-items: center;
  font-size: 15px;
  color: $color-grey-new1;

  .new-password-card {
    width: 100%;
    height: auto;
    max-width: 940px;
    min-height: 500px;
    margin: 40px auto 0;
    border-radius: 23px;
    box-shadow: 0 0 12px 0 rgba(2, 2, 2, 0.17);
    background-color: $color-real-white;
    text-align: center;
    position: relative;
    padding: 15px;

    .new-password-inner {
      margin: 0 auto;
      text-align: center;
      width: 100%;
      max-width: 292px;
      padding-top: 100px;
      padding-bottom: 100px;

      .card-title {
        font-size: 34px;
        font-weight: 700;
        color: $new-brand-medium-dark;
        padding-bottom: 25px;
      }

      .card-text {
        text-align: left;
        font-size: 14px;
        line-height: 18px;
      }

      .card-subtitle {
        color: $color-real-black;
        font-weight: 700;
      }

      .link {
        color: $new-brand-link-color;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        padding-bottom: 10px;
        overflow-wrap: break-word;
      }

      a {
        color: $new-brand-link-color;
        text-decoration: none;
      }

      input:focus-visible {
        background-color: $color-real-black;
        color: $color-real-black;
        outline: 2px solid $new-brand-medium-dark;
      }

      .inputRow {
        padding-bottom: 35px;
        position: relative;

        input {
          background-color: $color-real-white;
          border: 1px solid $color-grey-new2;
          border-radius: 5px;
          padding: 10px 20px;
          font-size: 13px;
          color: $color-black;
          width: 100%;
          line-height: 1.15;
          margin: 0;
          font-weight: 500;
          -webkit-font-smoothing: antialiased;
          box-sizing:border-box
        }
      }

      .submitRow {
        position: relative;
        text-align: center;
        padding-top: 30px;

        .submitBtn {
          width: 100%;
          cursor: pointer;
          font-weight: 400;
          text-transform: uppercase;
          background-color: $new-brand-light;
          border: 2px solid $new-brand-light;
          color: $color-real-white;
          padding: 5px 16px;
          border-radius: 4px;
          font-size: 14px;
          line-height: 20px;

          &:hover {
            background-color: $new-brand-dark;
          }
        }
      }

      input[type=email], input[type=password], input[type=search], input[type=text] {
        -webkit-appearance: none;
        -moz-appearance: none;
      }

      .messageOuter {
        display: block;
        height: 12px;
      }
      .message {
        text-align: center;
        color: $color-real-black;
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;

        &.error {
          color: $color-error;
        }
      }

      .errorMsg1, .errorMsg2 {
        position: absolute;
        margin-top: 0;
        margin-left: 0;
        background-color: $color-error;
        padding: 5px 15px;
        color: $color-real-white;
        font-size: 10px;
        text-align: left;
        line-height: 12px;
      }

      .feedback {
        color: $color-real-black;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
      }
    }
  }
}

@media (max-width: $breakpoint-phone) {
  .new-password-page-wrapper {
    .new-password-card {
      border-radius: 23px 23px 0 0;
    }
  }
}
