@import "../../assets/redesignedCss/main";

.logoToolbar {
  background: $color-real-white;
  box-shadow: 0 6px 80px rgba(138, 138, 138, 0.25);
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;

  .logo img {
    height: 35px;
  }

  .userInfo {
    .userName {
      padding-right: 30px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
    .closeButton {
      text-decoration: none;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      padding: 10px 12px;
      border: 1px solid;
      border-radius: 6px;
      color: #2B2B2B;
      cursor: pointer;
    }
  }
}

.invoicePageWrapper {
  box-sizing: border-box;
  background: #F4F4F4;
  min-height: 100vh;
}

.backButton {
  width: 100%;
  margin: 0 auto 10px;
  max-width: 990px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding-left: 24px;
  padding-top: 15px;

  .materialIcon {
    font-size: 16px;
    color: $color-neutral6;
  }
  .backButtonText {
    padding-left: 4px;
    color: $color-neutral6;
  }
}
.mainTitle {
  font-weight: 700;
  font-size: 30px;
  padding-bottom: 20px;
  max-width: 990px;
  margin: 0 auto;
  width: 100%;
  padding-left: 20px;
}

.invoicePaymentWrapper {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .invoiceContent {
    background-color: $color-real-white;
    padding: 64px;
    width: 400px;
    border-radius: 23px;
    box-shadow: 0 0 12px rgba(2, 2, 2, 0.1738);

    &.topMargin {
      margin-top: 120px;
    }
    &.noPadding {
      padding: 29px 50px;
    }
    .title {
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
      color: #161616;
      padding-bottom: 32px;
    }

    .subtitle {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #2B2B2B;
    }

    .formContainer {
      padding-top: 16px;

      .label {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #575757;
        padding-top: 8px;

        input {
          background: #FFFFFF;
          border: 1px solid #757575;
          border-radius: 4px;
          color: #161616;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          width: 100%;
          box-sizing: border-box;
          outline: none;
          padding: 9px 12px;
        }

        &.hasError {
          input {
            border: 1px solid $color-error;
          }
        }
      }
    }
    #paymentStripeForm .title {
      font-size: 18px;
      padding-bottom: 16px;
    }
    .acceptedCcIcons {
      padding: 12px 0 0;
      line-height: 1;

      span.acceptedCardIcon {
        opacity: 0.5;

        &.active {
          opacity: 1;
        }
      }
    }
  }
  .invoiceSuccessWrapper {
    background: #FFFFFF;
    border: 1px solid #757575;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 70px;
    max-width: 340px;
    width: 100%;

    .successSubtitle {
      font-weight: 700;
      font-size: 30px;
      padding-bottom: 24px;
    }
    .successDescription {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .invoiceDetails {
    margin-top: 0;
    padding: 0 64px;
    width: 374px;
    color: $color-neutral6;

    .detailsTitle {
      font-size: 20px;
      padding-bottom: 16px;
      border-bottom: 1px solid $color-neutral-new36;
    }
    .detailsSubtitle {
      padding-top: 16px;
      font-size: 20px;
      color: $color-black;
    }
    .detailsLine {
      font-size: 16px;
      letter-spacing: 0.02em;
      border-top: 1px solid $color-neutral4;
      padding: 8px 0;

      &.firstLine {
        border-top: 0;
      }

      .lineDescription {
        padding: 0 10px 0 0;
      }
      .linePrice {
        padding: 0 0 0 10px;
      }
    }
    .detailsTotal {
      color: $color-black;
      letter-spacing: 0.02em;
      padding-top: 8px;
      font-weight: bold;
      border-top: 1px solid ;
    }
    .detailsLine, .detailsTotal {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  #paymentStripeForm {
    .checkoutButtonWrapper {
      margin-top: 10px;
    }
  }
  .checkoutButtonWrapper {
    max-width: 100%;
    width: 100%;
    margin: 0 auto 30px;

    button.textButton.cta {
      outline: none;
      text-transform: uppercase;
      background-color: $color-cta-lighter;
      border: 0;
      width: 100%;
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      padding: 8px 30px;

      &.checkoutDisabled {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #88EBAC;
        color: $color-white;
        cursor: not-allowed;

        &:hover {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #88EBAC;
          color: $color-white;
          cursor: not-allowed;
        }
      }
      &:hover {
        background-color: $color-cta-darker;
      }
    }
  }
  .errorMessages {
    padding-top: 3px;
    padding-bottom: 2px;
    height: 20px;
    color: $color-error;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
  .formLabel {
    text-align: left;
    color: $color-neutral6;
    font-size: 14px;
    line-height: 21px;
  }

  .field, .zip .field.zipField {
    outline: none;
    border-radius: 4px;
    border: 1px solid $color-neutral4;
    padding: 8px;
    font-size: 16px;
    color: $color-neutral-new15;
    background-color: transparent;

    &::placeholder {
      color: $color-neutral-new24;
    }
  }

  .upgradeTermsWrapper {
    padding-top: 10px;
    font-size: 12px;
    color: $color-real-black;
    text-align: center;

    a {
      color: $color-brand-medium-dark;
      text-decoration: underline;

      &:hover {
        color: $color-brand-hover;
      }
    }
  }
  .stripeCardFormWrapper {
    .payment-errors {
      position: absolute;
      bottom: 0;
    }
  }
}
@media (max-width: $breakpoint-desktop) {
  .invoicePaymentWrapper {
    flex-direction: column;
    box-sizing: border-box;

    .invoiceContent {
      width: 100%;
      max-width: 100%;
      box-sizing: border-box;

      &.topMargin {
        margin-top: 20px;
      }

      .formContainer {
        .label {
          input {
            box-sizing: border-box;
          }
        }
      }
    }
    .invoiceDetails {
      box-sizing: border-box;
      padding-top: 40px;
    }
    .invoiceSuccessWrapper {
      box-sizing: border-box;
      width: 100%;
      max-width: 100%;
    }
  }
}
