
$color-real-white: #FFFFFF;
$color-real-black: #000000;

$color-white-old: #f9f9f9;

$color-neutral1: #2b2b2b;
$color-neutral1-light: #5e5e5e;
$color-neutral1-dark: #262626;

$color-neutral2: #4f4f4f;
$color-neutral2-light: #7b7b7b;
$color-neutral2-dark: #3b3b3b;

$color-neutral3: #868686;
$color-neutral3-light: #a4a4a4;
$color-neutral3-dark: #646464;

$color-neutral4: #CCCCCC;
$color-neutral4-light: #d8d8d8;
$color-neutral4-dark: #989898;

$color-neutral5: #E1E1E1;
$color-neutral5-light: #e8e8e8;
$color-neutral5-dark: #a8a8a8;

$color-neutral6: #575757;
$color-neutral7: #757575;

$color-ok: #64b32d;
$color-cta: #008731;
$color-cta-lighter: #00B341;
$color-cta-light: #008630;
$color-cta-dark: #008630;
$color-cta-darker: #008731;

$color-error: #EE3737;
$color-error-light: #F26969;
$color-error-dark: #B22929;
$color-error-form: #B00020;

$color-warn: #F28100;
$color-warn-light: #F5A040;
$color-warn-dark: #B56000;

$color-warn-alt: #F2BE00;
$color-warn-alt-light: #F5CE40;
$color-warn-alt-dark: #B58E00;

$color-warn-alt-new1: #f5c515;

$color-secondary: #8389CC;

$color-success: #4FB947;

$color-green-new1: #58BD50;
$color-green-new2: #0DA2A4;

$color-blue-new1: #81bcdb;
$color-blue-new2: #328FFB;
$color-blue-new5: #0CA2A4;

$color-red-new1: #CF4D00;

$color-neutral-new1: #555555;
$color-neutral-new2: #323232;
$color-neutral-new3: #8c8c8c;
$color-neutral-new4: #F7F7F7;
$color-neutral-new5: #EDEDED;
$color-neutral-new6: #666666;
$color-neutral-new7: #4d4d4d;
$color-neutral-new8: #696969;
$color-neutral-new9: #DDDDDD;
$color-neutral-new10: #333333;
$color-neutral-new11: #E6F6E5;
$color-neutral-new12: #EEF9FF;
$color-neutral-new13: #636363;
$color-neutral-new14: #EEF9FF;
$color-neutral-new15: #424770;
$color-neutral-new16: #444444;
$color-neutral-new17: #121212;
$color-neutral-new18: #e9e9e9;
$color-neutral-new19: #b6b6b6;
$color-neutral-new20: #d3d3d3;
$color-neutral-new21: #919191;
$color-neutral-new22: #656565;
$color-neutral-new23: #a7a7a7;
$color-neutral-new24: #aab7c4;
$color-neutral-new25: #4a4a4a;
$color-neutral-new26: #454545;
$color-neutral-new27: #aaaaaa;
$color-neutral-new28: #222222;
$color-neutral-new29: #f9f9f9;
$color-neutral-new30: #CDCDCD;
$color-neutral-new31: #F2F2F2;
$color-neutral-new32: #B4B4B4;
$color-neutral-new33: #E5E5E5;
$color-neutral-new34: #F0F0F0;
$color-neutral-new35: #565656;
$color-neutral-new36: #8D8D8D;

$color-blue-new1: #81bcdb;
$color-blue-new2: #328FFB;
$color-blue-new3: #7EC4E5;
$color-blue-new4: #005178;
$color-blue-new5: #0CA2A4;

$color-red-new1: #CF4D00;

$color-orange-new1: #f38100;
$color-orange-new2: #F4C556;

$color-grey-new1: #807F7F;
$color-grey-new2: #979797;
$color-grey-new3: #888888;
$color-grey-new4: #a9a9a9;
$color-grey-new5: #898888;

$color-black-new1: #494949;

$color-white-new1: #F4F4F4;
$color-white-new2: #F6F6F6;
$comparison-border-color: rgba(0, 137, 204, 0.5);

$color-brand: #1F7F8E;
$color-brand-light: #29a2b5;
$color-brand-lighter: #29a2b5;
$color-brand-medium-dark: #1F7F8E;
$color-brand-mediu-dark-hover: #003a5d;
$color-brand-dark: #066477;
$color-brand-transparent: rgba(0,102,152,0.4);
$color-brand-darker: #001c33;
$color-brand-disabled: #29a2b5;
$color-brand-hover: #066477;

$new-brand-light: #1F7F8E;
$new-brand: #29a2b5;
$new-brand-dark: #066477;
$new-brand-medium-dark: #003a5d;
$new-brand-darker: #001c33;
$new-brand-link-color: #96006b;


/// REDESIGN
/// Colors
///
$color-sky: #2CAFC0;
$color-orange: #F78320;
$color-yellow: #F7C223;
$color-magenta: #96006B;
$color-navy: #003A5D;
$color-teal: #1F7F8E;
$color-teal-hover: #066477;

// this is the brand teal, can't use it on buttons, not enough contrast
$color-wevideo-teal: #28a2b5;


$color-white: #DCDCDC;
$color-lightgray: #F3F3F3;
$color-gray: #8A8A8A;
$color-gray-dark: #757575;
$color-black: #161616;