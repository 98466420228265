@import "../../../assets/redesignedCss/main";

.header {
  padding: 20px;

  .proxyLinks {
    ol {
      display: inline-block;
      padding: 5px 20px;

      a {
        color: $color-real-white;
        background-color: $color-brand;
        padding: 10px 30px;
        text-decoration: none;

        &:hover {
          background-color: #000066;
        }
      }
    }
  }
}
.testerClass {
  background-color: $color-brand;
  color: $color-real-white;
  text-align: center;
  padding: 10px;
}