@import "./colors";

///BUTTONS
button,
.button {
  cursor: pointer;
  display: inline-block;
  font-size: 0.92rem;
  text-align: center;
  transition: all 0.15s linear;
  white-space: normal;
}
button,
.button,
input[type=button],
input[type=submit],
input[type=text] {
  font-family: "Montreux Grotesk Soft", sans-serif;
}

button:disabled,
.button:disabled {
  background-color: #D0D0D0;
  border-color: #D0D0D0;
  color: #E6E6E6;
}

button:hover,
button:focus,
.button:hover,
.button:focus {
  text-decoration: none;
}

button:active,
.button:active {
  text-decoration: none;
}

.currentPlanTextWrapper {
  padding-bottom: 8px;
  padding-top: 10px;

  .currentPlanText {
    font-style: italic;
    color: $color-gray-dark;
    font-weight: $weight-demibold;
    font-size: 1.1rem;
  }
}
///BUTTONS

///BUTTON
.button {
  $b: &;

  display: inline-block;
  position: relative;
  background-color: $color-teal;
  padding: 0.4rem 1.6rem;
  border-radius: 1.2rem;
  font-weight: $weight-demibold;
  text-align: center;
  color: #fff;
  transition: background-color $entrance-easing $slow, padding $entrance-easing $slow, color $entrance-easing $slow, border $entrance-easing $slow;
  line-height: 1.5;
  font-size: 1rem;

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.7rem;
    color: #fff;
    width: 0;
    height: 0.77rem;
    opacity: 0;
    transition: opacity $entrance-easing $moderate, width $entrance-easing $moderate;
  }

  &.-secondary {
    padding: calc(0.4rem - 2px) calc(1.6rem - 2px);
    background-color: transparent;
    border: 2px solid $color-teal;
    color: $color-navy;
  }

  &.-secondary #{$b}__icon {
    color: $color-navy;
  }

  &.-tertiary {
    padding: calc(0.4rem - 2px) calc(1.6rem - 2px);
    background-color: transparent;
    border: 2px solid $color-yellow;
    color: #fff;
  }

  &:hover,
  &:active {
    padding: .4rem 2.1rem .4rem 1.1rem;

    #{$b}__icon {
      opacity: 1;
      width: 1.15rem;
    }
  }

  &.-secondary:hover,
  &.-secondary:active {
    padding: calc(.4rem - 2px) calc(2.1rem - 2px) calc(.4rem - 2px) calc(1.1rem - 2px);
    background-color: transparent;
  }

  &.-tertiary:hover,
  &.-tertiary:active {
    padding: calc(.4rem - 2px) calc(2.1rem - 2px) calc(.4rem - 2px) calc(1.1rem - 2px);
    background-color: transparent;
    border: 2px solid $color-yellow;
    color: #fff;
  }
}
///BUTTON

///CTA
.cta {
  $b: &;

  display: inline-block;
  color: $color-magenta;
  font-size: 0.888888rem;
  font-weight: 600;
  cursor: pointer;

  &__icon {
    width: 1.15rem;
    height: 0.73rem;
    vertical-align: -1px;
    transition: transform $moderate $entrance-easing;
  }

  &:hover #{$b}__icon,
  &:active #{$b}__icon {
    transform: translateX(0.3em);
  }
}
///CTA