///TYPOGRAPHY
html[lang^="ja"] body,
html[lang^="zh"] body,
html[lang^="ko"] body {
  line-break: strict;
  word-break: break-all;
}

/* Paragraphs */

p {
  font-size: 1rem;
  margin: 0 0 1.4rem;
}

/* Anchors */

a {
  cursor: pointer;
  text-decoration: none;
}

/* Headings */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1.4rem;
}

/* Lists */

ul,
ol {
  margin: 0 0 1.4rem;
}

ul ul,
ol ul,
ul ol,
ol ol {
  margin: 0;
}

ul.no-list {
  list-style: none;
}

/* Code blocks */

pre {
  overflow: auto;
}

code {
  vertical-align: bottom;
}

/* Blockquotes */

blockquote {
  border-left: 2px solid;
  margin: 0 0 1.4rem;
  padding-left: 15px;
}

/* Horizontal rules */

hr {
  background-color: #CCC;
  border: none;
  color: #CCC;
  height: 1px;
}

/* Image alt text */

img {
  font-size: 14px;
  word-break: normal;
}
///TYPOGRAPHY